const questions = [
  // 1 Слайд \\ Перу [1]
  {
    questionText:
      "Peru adalah negara pegunungan di mana budaya dari penduduk asli dan pendatang asal Eropa jalin-menjalin dalam membentuk sebuah pola yang orisinal.",
    answerOptions: [
      {
        answerText:
          "Peru adalah negara terbesar ketiga di  Amerika Selatan setelah Brasil dan Argentina.",
        answerResult: "Benar! Negara ini memiliki luas 1.285.000 km2",
        result: true,
      },
      {
        answerText:
          "Di Peru, mereka berhasil menjinakkan llama liar hanya pada pertengahan abad ke-20.",
        answerResult:
          "Sama sekali tidak. Llama sudah menjadi binatang jinak lebih dari 3.000 tahun silam.",
        result: false,
      },
      {
        answerText:
          "Hiasan kepala penduduk asli Peru - topi rajut dengan penutup telinga - disebut Quipu.",
        answerResult:
          "Tidak benar. Quipu adalah metode penulisan simpul kuno. Sementara itu, topi yang kita maksud ini disebut Chullo.",
        result: false,
      },
      {
        answerText:
          "Di gurun Nazca, terdapat beberapa piramida misterius - artefak dari peradaban kuno.",
        answerResult:
          "Tidak diragukan jika penduduk asli Peru meninggalkan jejak di gurun Nazca. Namun, itu bukanlah piramida, melainkan geoglif - lukisan raksasa di atas permukaan tanah.",
        result: false,
      },
    ],
    image: "images/01.webp",
  },

  // 2 слайд \\ Аргентина [4]
  {
    questionText:
      "Sambil berkeliling menjelajahi Argentina, kamu bisa melihat monyet-monyet liar di hutan bagian utara dan pinguin di pantai bagian selatan yang dingin.",
    answerOptions: [
      {
        answerText:
          "Arti nama dari ibu kota Argentina adalah “Bunga yang Indah” dalam bahasa Spanyol.",
        answerResult:
          "Ibu kota Argentina adalah Buenos Aires, yang jika diartikan berarti “Angin yang bersih”",
        result: false,
      },
      {
        answerText:
          "Tim sepak bola nasional Argentina telah menjadi juara piala dunia lebih banyak dibandingkan tim lainnya.",
        answerResult:
          "Sayangnya, tim sepak bola nasional Argentina hanya pernah memenangkan piala dunia sebanyak 3 kali. Italia dan Jerman menjadi juara sebanyak 4 kali dan Brasil - 5 kali.",
        result: false,
      },
      {
        answerText: "Sebagian besar penduduk Argentina adalah vegetarian.",
        answerResult:
          "Faktanya adalah sebaliknya. Argentina menduduki peringkat pertama di dunia dalam hal konsumsi daging. Seluruh masakan asli Argentina berbahan dasar daging.",
        result: false,
      },
      {
        answerText:
          "Orang Argentina meminum “mate”, teh tradisional mereka, melalui sedotan dari wadah khusus yang disebut calabaza atau labu air.",
        answerResult:
          "Tepat sekali! Dan sedotan bersaringan itu disebut bombilla.",
        result: true,
      },
    ],
    image: "images/02.webp",
  },

  // 3 слайд \\ Вьетнам [1]
  {
    questionText:
      "Negara yang terdiri atas hutan belantara sekaligus kota-kota besar ini terbentang dari utara ke selatan di pesisir Laut Cina Selatan. Apa yang kita ketahui tentangnya? Mari kita cek!",
    answerOptions: [
      {
        answerText:
          "Di selatan Vietnam, terdapat gurun kecil dengan bukit pasir.",
        answerResult:
          "Itu benar. Kamu bisa mengendarai ATV di gurun pasir ini atau cukup meluncur saja ke bawah seperti saat bermain perosotan.",
        result: true,
      },
      {
        answerText:
          "Vietnam menduduki peringkat pertama dunia dalam hal produksi kopi.",
        answerResult:
          "Anehnya, hanya Brasil yang bisa menyaingi Vietnam dalam hal produksi kopi. Jadi, negara ini menduduki tempat terhormat kedua.",
        result: false,
      },
      {
        answerText:
          "Mata uang Vietnam - dong - terbuat dari kain agar daya tahannya lebih baik.",
        answerResult:
          "Dong benar-benar tidak bisa dirobek, kotor, atau basah. Namun, mereka terbuat dari plastik.",
        result: false,
      },
      {
        answerText:
          "Pho, mie kuah tradisional yang terkenal dari Vietnam, sudah ada sejak dahulu kala.",
        answerResult:
          "Sejarah berkata lain. Daging yang digunakan untuk membuat kaldu untuk mie mulai dikonsumsi oleh penduduk Vietnam pada abad ke XIX. Sebelum itu, hewan ternak bertanduk ini hanya dimanfaatkan untuk membajak dan mengangkut muatan.",
        result: false,
      },
    ],
    image: "images/03.webp",
  },

  // 4 слайд \\ Малайзия [3]
  {
    questionText:
      "Negara tetangga kita ini memiliki banyak kejutan. Tidak heran jika Malaysia menjadi 1 dari 10 negara di dunia yang paling banyak dikunjungi wisatawan. Berikut adalah beberapa pernyataan tentang negara ini. Namun, hanya ada satu yang benar.",
    answerOptions: [
      {
        answerText:
          "Wilayah perbatasan antara Indonesia dan Malaysia terbentang sepanjang pulau Kalimantan.",
        answerResult:
          "Lebih tepatnya, ini adalah wilayah perbatasan di antara 3 negara. Bagian dari pulau ini tidak hanya menjadi milik Indonesia dan Malaysia namun juga milik Brunei Darussalam.",
        result: false,
      },
      {
        answerText: "Ada 50 jenis apel yang tumbuh di Malaysia.",
        answerResult:
          "Terlepas dari buah paling eksotis yang bisa saja ditemukan di Malaysia, apel biasa tidak tumbuh di sini. Mereka selalu diimpor.",
        result: false,
      },
      {
        answerText: "Kamu akan didenda jika mengendarai gajah di dalam kota.",
        answerResult:
          "Benar sekali. Selain itu, pastinya jauh lebih nyaman untuk kamu mengendarai mobil atau motor yang jumlahnya memang sangat banyak di Malaysia.",
        result: true,
      },
      {
        answerText:
          "Beruang terkecil di dunia, Beruang, hidup di Malaysia. Ukurannya hanya sebesar ukuran anjing pada umumnya.",
        answerResult:
          "Tidak juga. Beruang di Malaysia memang beruang terkecil di dunia, tetapi tingginya bisa mencapai 1,5 meter dan bobotnya mencapai hingga 65 kilogram.",
        result: false,
      },
    ],
    image: "images/04.webp",
  },

  // 5 слайд \\ Бразилия [2]
  {
    questionText:
      "Samba, Anakonda, Capoeira — semua ini ada di Brasil, yang diselimuti hujan hujan tropis. Hal lain apa saja yang juga terkenal di sini? Pilih pernyataan yang benar.",
    answerOptions: [
      {
        answerText:
          "Ibu kota Brasil adalah São Paulo. Sang arsitek Lúcio Costa membangun kota ini dalam bentuk kupu-kupu atau burung terbang.",
        answerResult:
          "Benar, ibu kota ini mengingatkan kita pada burung atau bahkan pesawat. Namun, kota ini disebut Brasilia.",
        result: false,
      },
      {
        answerText:
          "Ada begitu banyak tebu di Brasil sehingga dapat digunakan dalam produksi bahan bakar yang mampu menggantikan produk minyak bumi.",
        answerResult:
          "TTepat sekali! Brasil adalah negara teratas dalam hal produksi tebu. Dan juga jeruk.",
        result: true,
      },
      {
        answerText:
          "Karnaval terkenal di Brasil bisa berlangsung selama satu bulan.",
        answerResult:
          "Sebesar apa pun keinginan penduduk untuk memperpanjang hari libur ini, karnaval tersebut hanya berlangsung selama 5 hari. Setelahnya, ada masa prapaskah.",
        result: false,
      },
      {
        answerText:
          "Kita semua tahu bahwa penduduk Brasil adalah pemain bola yang andal. Hal ini sudah pasti, mengingat permainan ini bahkan disebutkan dalam lagu kebangsaan mereka.",
        answerResult:
          "Sayangnya, tidak. Lagu kebangsaan Brasil sebagian besar bercerita tentang kecintaan mereka terhadap tanah air, masa lalu yang gemilang, serta masa depan yang cemerlang.",
        result: false,
      },
    ],
    image: "images/05.webp",
  },

  // 7 слайд \\ Индонезия [3]
  {
    questionText:
      "Seharusnya, ini gampang buat kamu. Anak-anak sekolah di Indonesia pun pastinya bisa menebak yang mana dari 4 pernyataan ini yang benar dan yang salah.",
    answerOptions: [
      {
        answerText:
          "Ada 9 situs warisan dunia UNESCO di Indonesia. Semuanya berhubungan dengan alam seperti Taman Nasional Komodo misalnya.",
        answerResult:
          "Hanya sebagian dari situs tersebut yang alami. Sisanya adalah buatan tangan manusia seperti Candi Borobudur dan Candi Prambanan.",
        result: false,
      },
      {
        answerText: "Penduduk Indonesia sangat gemar tidur.",
        answerResult:
          "Mungkin itu benar, tetapi kebanyakan warga Indonesia bangun sangat awal bahkan sebelum pukul 05.00 pagi. Pagi-pagi, mereka bisa salat, berangkat kerja sebelum kemacetan mulai merajalela, atau pergi ke sekolah.",
        result: false,
      },
      {
        answerText: "Indonesia adalah negara ojek",
        answerResult:
          "Benar sekali. Karena lalu lintas yang sangat padat, alat transportasi yang mudah bermanuver ini memang sangat populer.",
        result: true,
      },
      {
        answerText:
          "Kucing dapat ditemukan di mana saja di Indonesia, tetapi sebenarnya populasi mereka tidak lebih dari 1.000.000",
        answerResult:
          "Satu juta kucing untuk negara luas ini sepertinya sangat sedikit. Ada lebih dari 30.000.000 kucing di sini. Angka ini belum termasuk pahatan kucing dari Bali.",
        result: false,
      },
    ],
    image: "images/06.webp",
  },

  // 8 слайд \\ Мексика [2]
  {
    questionText:
      "Ketika kita membahas Meksiko, kita pasti membayangkan orang mengenakan sombrero dan gurun yang dijejali kaktus. Namun, sampai di mana seluruh stereotip ini benar? Mari kita lihat!",
    answerOptions: [
      {
        answerText:
          "Tidak ada taksi di ibu kota Meksiko, tetapi sistem transportasi umumnya sudah maju.",
        answerResult:
          "Sebaliknya. Meksiko berada di urutan pertama di dunia dalam urusan jumlah taksi. Ada lebih dari 100.000 taksi di sana.",
        result: false,
      },
      {
        answerText:
          "Berondong Jagung ditemukan di Meksiko lebih dari 4.000 tahun silam.",
        answerResult:
          "Benar sekali! Penduduk asli Meksiko memanaskan jagung jenis khusus dan gulma untuk dimakan atau untuk hiasan.",
        result: true,
      },
      {
        answerText:
          "Salah satu simbol Meksiko adalah anjing Chihuahua - diangkut oleh penjajah Spanyol yang memanfaatkan hewan ini untuk menangkap tikus di dalam kapal.",
        answerResult:
          "Semua Chihuahua adalah 100% dari Meksiko. Binatang lucu dan pemberani ini adalah keturunan dari Techichi yang sudah punah dan dianggap suci oleh suku-suku di Meksiko.Seluruh Chihuahua adalah 100% dari Mexico. Binatang lucu dan pemberani ini adalah keturunan dari Techichi yang telah punah yang dianggap suci oleh suku-suku di Mexico.",
        result: false,
      },
      {
        answerText: "Di Meksiko, kaktus hanya digunakan untuk membuat Tequila.",
        answerResult:
          "Tentunya tidak. Beragam jenis kaktus dapat dijadikan bahan makanan, komponen obat-obatan, sampo, pewarna, dan bahkan kain.",
        result: false,
      },
    ],
    image: "images/07.webp",
  },
];

export default questions;
